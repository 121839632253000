import React from 'react';
import Login from "./Routes/Login";
import {Route, Router, Switch} from 'react-router-dom';
import history from './Utils/history';
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import {ThemeProvider} from "@material-ui/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import Account from "./Routes/Account";
import {useAuth0} from "@auth0/auth0-react";
import {Subscription} from "./Routes/Subscription";
//import Navbar from "./Components/Navs";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.versaquant.com/">
                VersaQuant
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function App() {
    const {isAuthenticated} = useAuth0();
    const darkTheme = createMuiTheme({
        palette: {
            type: "dark",
            secondary: {
                main: '#7c838d'
            }
        },
        chart: {
            background: 'black',
            input: '#7c838d',
            text: 'white',
            gridLine: '#292b2d'
        },
        font: {
            positiveAmt: 'green',
            negativeAmt: 'red',
        },
        table: {
            header: {
                background: 'black',
                text: 'white',
            }
        }
    });
    // const [bucketName, setBucketName] = React.useState('');
    //
    // useEffect(() => {
    //     DataService.fetchXMLList()
    //         .then(res => setBucketName(res));
    // }, []);

    return (
        <ThemeProvider theme={darkTheme}>
            <div>
                <Router history={history} basename="/">
                    <Switch>
                        <Route exact path="/" component={Login}/>
                        {/*<Route path="/explorer">*/}
                        {/*  <MainPage isDashboard={true} bucket={bucketName}/>*/}
                        {/*</Route>*/}

                        {isAuthenticated && <Route path="/explorer" > <Account/></Route>}
                        <Route path="/pay"><Subscription/></Route>

                       </Switch>


                </Router>
                <Box pt={3}>
                    <Copyright/>
                </Box>
                <br/>
                <br/>
                <br/>

            </div>
        </ThemeProvider>
    );
}