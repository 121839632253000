
import React from "react";
import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';

import {makeStyles} from "@material-ui/core/styles";


import {
    Box,
    Container,
    Row,
    Column,
    FooterLink,
    Heading,
} from "./Footers";
import Navbar from "../Components/Navs";

const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            width:"60px",
            fontWeight:'xx-large'

        },
        toolbar: {
paddingLeft:theme.spacing(0),
        },








    }
))


export function Subscription() {

    const classes = useStyles();

    return (

        <div className={classes.toolbar}>

<Navbar/>



                <br/>
                <br/>
                <br/>
                <br/>
                <br/>


                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <li/>
                <PricingTable  highlightColor='#1976D2'>
                    <PricingSlot buttonText='TRY IT FREE' title='FREE' priceText='$0/month' onClick={(e) => {
                        e.preventDefault();
                        window.location.href = 'https://www.versaquant.com/download';
                    }}>
                        <PricingDetail> <b>1</b> Account Optimized</PricingDetail>
                        <PricingDetail> Auto Placing Trades</PricingDetail>
                        <PricingDetail> <br/></PricingDetail>
                        <PricingDetail> <br/></PricingDetail>
                    </PricingSlot>
                    <PricingSlot highlighted buttonText='SIGN UP' title='BASIC' priceText='$25/month' onClick={(e) => {
                        e.preventDefault();
                        window.location.href = 'https://buy.stripe.com/aEU02F2xTcw3adi3cd';
                    }}>
                        <PricingDetail> <b>Unlimited</b> Accounts Optimized</PricingDetail>
                        <PricingDetail> Auto Placing Trade</PricingDetail>
                        <PricingDetail> Advisor Access</PricingDetail>
                        <PricingDetail> <br/></PricingDetail>
                    </PricingSlot>
                    <PricingSlot buttonText='SIGN UP' title='PROFESSIONAL' priceText='$199/year' onClick={(e) => {
                        e.preventDefault();
                        window.location.href = 'https://buy.stripe.com/8wMcPr8Wh2Vt99e000';
                    }}>
                        <PricingDetail> <b>Unlimited</b> Account Optimized</PricingDetail>
                        <PricingDetail> Auto Placing Trades</PricingDetail>
                        <PricingDetail> Advisor Access</PricingDetail>
                        <PricingDetail> <br/></PricingDetail>
                    </PricingSlot>
                    <PricingSlot buttonText='CONTACT US' title='ENTERPRISE' priceText='Contact Us' onClick={(e) => {
                        e.preventDefault();
                        window.location.href = 'https://www.versaquant.com/#5';
                    }}>
                        <PricingDetail> <b>Unlimited</b> Account Optimized</PricingDetail>
                        <PricingDetail> Auto Placing Trades</PricingDetail>
                        <PricingDetail> Advisor Access</PricingDetail>
                        <PricingDetail> White Labeling</PricingDetail>
                    </PricingSlot>
                </PricingTable>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>



                <Box>
                <h1 style={{ color: "black",
                textAlign: "center",
                marginTop: "-50px",fontSize:"xx-large" }}>
VERSAQUANT                 </h1>
                <Container>
                <Row>
                <Column>
                <Heading>About Us</Heading>
                <FooterLink href="#">Aim</FooterLink>
                <FooterLink href="#">Vision</FooterLink>
                <FooterLink href="#">Testimonials</FooterLink>
                </Column>
                <Column>
                <Heading>Services</Heading>
                <FooterLink href="#">Writing</FooterLink>
                <FooterLink href="#">Internships</FooterLink>
                <FooterLink href="#">Coding</FooterLink>
                <FooterLink href="#">Teaching</FooterLink>
                </Column>
                <Column>
                <Heading>Contact Us</Heading>
                <FooterLink href="#">Coding</FooterLink>
                <FooterLink href="#">Pricing</FooterLink>
                <FooterLink href="#">About</FooterLink>
                <FooterLink href="#">Pay</FooterLink>
                </Column>
                <Column>
                <Heading>Social Media</Heading>
                <FooterLink href="#">
                <i className="fab fa-facebook-f">
                <span style={{ marginLeft: "10px" }}>
                Facebook
                </span>
                </i>
                </FooterLink>
                <FooterLink href="#">
                <i className="fab fa-instagram">
                <span style={{ marginLeft: "10px" }}>
                Instagram
                </span>
                </i>
                </FooterLink>
                <FooterLink href="#">
                <i className="fab fa-twitter">
                <span style={{ marginLeft: "10px" }}>
                Twitter
                </span>
                </i>
                </FooterLink>
                <FooterLink href="#">
                <i className="fab fa-youtube">
                <span style={{ marginLeft: "11px" }}>
                Youtube
                </span>
                </i>
                </FooterLink>
                </Column>
                </Row>
                </Container>
                </Box>
            </div>
    )
}