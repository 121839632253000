import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {StyledTableCell} from "../Utils/listItems";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useAuth0} from "@auth0/auth0-react";
import Navbar from "../Components/Navs";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },

        
        
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    container: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(6),
        paddingLeft: theme.spacing(10),
        height: 1000,
        fontSize:"xx-large",
        backgroundColor:"white"





    },
        logo:{
            maxHeight:"100px",
            height:"84px",
            display:"flex",
            position:"absolute",
            left:"0",
            top:"0",
            transition: "all 0.2s ease-in-out",







        },
        App:{
        alignItems:"right",
            width:"30%",
            position:"absolute",
            right:"10px"
        }


}
))

export default function Account() {
    const classes = useStyles();
    const { logout } = useAuth0();

    return (

        <div >
<Navbar/>

            <div className={classes.container}>
            <CssBaseline/>
            <Typography style={{fontSize:'smaller', color:"black"}} variant="h3" component="h4"  >
                Account Summary     </Typography>
            <Container maxWidth="xl" className={classes.container}>
                <Accordion style={{width: '50%', backgroundColor: 'lightgrey', fontSize:"large"}} defaultExpanded={true}>
                    <AccordionSummary className={classes.panelSummary}
                                      expandIcon={<ExpandMoreIcon/>}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                    >
                        &nbsp;&nbsp;&nbsp;
                        <Typography style={{color:"black"}} component="h8" variant="h7" className="root "  >VQLAB Download links for Registered Users</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>

                           <a target="_blank" rel="noopener noreferrer" href="https://www.versaquant.com/download#2" style={{color:"black"}}   >
                               <li>Download link for Windows Users </li>  </a>
                            <a href="https://www.versaquant.com/download#3" target="_blank" rel="noopener noreferrer" style={{color:"black"}}>
                                <li>Download link for Mac Users </li>
                            </a>
                        </Grid>
                    </Grid>
                </AccordionDetails>
                </Accordion>
                        <Accordion style={{width: '50%', backgroundColor: 'lightgrey'}} defaultExpanded={true}>
                            <AccordionSummary className={classes.panelSummary}
                                              expandIcon={<ExpandMoreIcon/>}
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                            >
                        &nbsp;&nbsp;&nbsp;
                        <Typography style={{color:"black"}}  component="h2" variant="h6">Subscriptions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table size="small" style={{backgroundColor:"lightgrey"}} >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{textAlign: 'center' ,backgroundColor:"lightgrey",color:"black" }}>Product</StyledTableCell>
                                    <StyledTableCell style={{position: 'relative', right: '15px',color:"black",backgroundColor: "lightgrey"}}>Purchase
                                        Dat</StyledTableCell>
                                    <StyledTableCell
                                        style={{position: 'relative', right: '15px',backgroundColor:"lightgrey",color:"black"}}>Cost</StyledTableCell>
                                    <StyledTableCell
                                        style={{position: 'relative', right: '15px',backgroundColor:"lightgrey",color:"black"}}>Users</StyledTableCell>
                                    <StyledTableCell style={{position: 'relative', right: '15px',backgroundColor:"lightgrey",color:"black"}}>Period End
                                        Date</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={1}>
                                    <StyledTableCell>-</StyledTableCell>
                                    <StyledTableCell>-</StyledTableCell>
                                    <StyledTableCell>-</StyledTableCell>
                                    <StyledTableCell>-</StyledTableCell>
                                    <StyledTableCell>-</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{width: '50%', backgroundColor: 'lightgrey'}} defaultExpanded={true}>
                    <AccordionSummary className={classes.panelSummary}
                                      expandIcon={<ExpandMoreIcon/>}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                    >
                        &nbsp;&nbsp;&nbsp;
                        <Typography style={{color:"black"}}  component="h2" variant="h6" >License Type</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{fontSize:"large",backgroundColor:"lightgrey",color:"black" }} >
                        Individual Non-Professional
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{width: '50%', backgroundColor: "lightgrey"}}>
                    <AccordionSummary className={classes.panelSummary}
                                      expandIcon={<ExpandMoreIcon/>}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                    >
                        &nbsp;&nbsp;&nbsp;
                        <Typography style={{color:"black"}} component="h2" variant="h6" >Payment Methods</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Typography style={{color:"black"}}>No Payment Methods on file</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button  variant="contained">
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{width: '50%', backgroundColor: 'lightgrey'}}>
                    <AccordionSummary className={classes.panelSummary}
                                      expandIcon={<ExpandMoreIcon/>}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                    >
                        &nbsp;&nbsp;&nbsp;
                        <Typography style={{color:"black"}} component="h2" variant="h6">Change Password</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Button variant="contained">
                                    Change Password
                                </Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>


                                <Button variant="contained" onClick={() => logout({ returnTo: window.location.origin,})}>
                                    Logout
                                </Button>


            </Container>
        </div>
        </div>
    );
 }
