import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
  background: lightgrey;
  height: 85px;
  display: flex;
  justify-content: space-evenly;
  padding: 0 1100px;
  z-index: 12;
  /* Third Nav */
  /* justify-content: flex-start; */
`;

export const NavLink = styled(Link)`
  color: black;
  display: flex;
  text-decoration: none;
  padding:18px 80px;
  cursor: pointer;
  font-size:22px ;
  justify-content: space-around;
  &.active {
    color: #000000;
  }
`;



export const NavMenu = styled.div`
  display: flex;
  margin-right: -24px;
  right:120px;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
  @media screen and (max-width: 768px) {
    display: none;
    
  }
`;

