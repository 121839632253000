
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useAuth0 } from "@auth0/auth0-react";
import logo from '../Utils/Logo.png';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor:"white",
        paddingLeft:theme.spacing(7),

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        paddingLeft: theme.spacing(14),

    },
    toolbarIcon: {
        paddingLeft:theme.spacing(4),

    },
    App: {
        paddingTop: theme.spacing(47),
        paddingBottom: theme.spacing(84),
        paddingLeft: theme.spacing(105),
        paddingRight:theme.spacing(20),
        height: 600,
        fontSize:"smaller",
        backgroundColor:"white"



    },
    fixedHeight: {
        height: 940,
    },

}));

export default function Login() {
    const classes = useStyles();
    const { loginWithRedirect } = useAuth0();
    return (
        <div className={classes.App} >
            <img className={classes.toolbarIcon} src={logo} alt="img-logo" align="center" />

            <br />
            <br/>
            <br/>
            <div className={classes.root}>
                <Button style={{right:'5px',align:"left",        padding: "7px 38px",
                    fontsize:"23px"}}  variant="contained" onClick={() => loginWithRedirect()}   >
                    Log In
                </Button>
                {' '}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button style={{align:"left",   fontsize:"23px"   ,   padding: "7px 38px"}} variant="contained" onClick={() => loginWithRedirect()}>
                    Sign Up
                </Button>
            </div>
        </div>

    );
}