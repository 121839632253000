import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import history from "./history";
import NumberFormat from "react-number-format";
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from "@material-ui/core/CircularProgress";
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.table.header.background,
        color: theme.table.header.text,
        textAlign: 'right'
    },
    body: {
        textAlign: 'center',
        fontSize: 14,
    },
}))(TableCell);

export const mainListItems = (
  <div>
    <ListItem button onClick={() => history.push('/explorer')}>
      <ListItemIcon>
        <TrendingUpIcon />
      </ListItemIcon>
      <ListItemText primary="AIQ BOT Performance" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>

  </div>
);

export const numFormatElement = (numValue, type) => {
    let [pref, suff] = ['', ''];
    let hStyle = {textAlign: 'right'};
    switch (type) {
        case '$':
            pref = '$'
            hStyle.textAlign = 'right'
            break;
        case '%':
            suff = '%'
            break;
        default:
            break;
    }
    if (numValue < 0) {
        pref = '(' + pref
        hStyle.color = '#d12621'
        suff = suff + ')'
    } else {
        hStyle.color = '#a4ff4f'
    }

    return <NumberFormat  value={Math.abs(numValue)} prefix={pref} suffix={suff} thousandSeparator={true}
                          decimalScale={2} isNumericString={true} displayType={'text'}
                          renderText={value => <div style={ hStyle }>{value}</div>} />
};

export const CircularIndeterminate = withStyles((theme) => ({
    root: {
        color: theme.table.header.text,
        textAlign: "center"
    }
}))(CircularProgress);