import React from 'react';
import { render } from 'react-dom';
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";


render(
    <Auth0Provider
        domain="vqdesktop.us.auth0.com"
        clientId="ytJ0Bn7GqB6xkosx5eqKq3SaK4FiBrM9"
        redirectUri={window.location.origin + "/explorer"}
    >
        <App />
    </Auth0Provider>,
    document.getElementById("root")
);