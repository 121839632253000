import React from 'react';
import {
    Nav,
    NavLink,
    NavMenu,

} from './NavbarElements';
import logo from "../Utils/Logo.png";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width:"60px",
        fontWeight:'xx-large'

    },
    toolbar: {
        paddingLeft:theme.spacing(0),
    },





    logo: {
        maxHeight: "100px",
        height: "84px",
        position: "absolute",
        left: "-2px"


    },
    }

))

const Navbar = () => {
    const classes = useStyles();


    return (
        <div>

            <Nav>
                <img src={logo} alt="img" className={classes.logo}/>
                <NavMenu className={classes.App}>


                    <NavLink to='/about' activeStyle>
                        Price
                    </NavLink>
                    <NavLink to='/events' activeStyle>
                        Data
                    </NavLink>
                    <NavLink to='/pay' activeStyle>
                        Subscribe
                    </NavLink>
                    <NavLink to='/team' activeStyle>
                        About
                    </NavLink>

                    {/* Second Nav */}
                    {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
                </NavMenu>

            </Nav>
        </div>

    );

}
export default Navbar;